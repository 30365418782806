import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  MenuItem,
  Snackbar,
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Stack,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PocketBase from 'pocketbase';
import dayjs from 'dayjs';

const pb = new PocketBase('https://pocketbase.telepathcare.com');

// Random data generation helpers
const randomFromArray = (arr) => arr[Math.floor(Math.random() * arr.length)];

const generateRandomData = () => {
  const firstNames = ['James', 'Mary', 'John', 'Patricia', 'Robert', 'Jennifer', 'Michael', 'Linda', 'William', 'Elizabeth'];
  const lastNames = ['Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez'];
  const cities = ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix', 'Philadelphia', 'San Antonio', 'San Diego', 'Dallas', 'San Jose'];
  const states = ['NY', 'CA', 'IL', 'TX', 'AZ', 'PA', 'FL', 'OH', 'GA', 'NC'];
  const countries = ['United States', 'Canada', 'Mexico', 'United Kingdom', 'France'];
  const genders = ['male', 'female', 'other'];

  const firstName = randomFromArray(firstNames);
  const lastName = randomFromArray(lastNames);
  
  return {
    firstName,
    lastName,
    email: `${firstName.toLowerCase()}.${lastName.toLowerCase()}${Math.floor(Math.random() * 999)}@example.com`,
    phoneNumber: `${Math.floor(Math.random() * 900) + 100}-${Math.floor(Math.random() * 900) + 100}-${Math.floor(Math.random() * 9000) + 1000}`,
    tos: true,
    dob: dayjs().subtract(Math.floor(Math.random() * 50) + 18, 'years'),
    gender: randomFromArray(genders),
    weight: Math.floor(Math.random() * 150) + 100,
    address: `${Math.floor(Math.random() * 9999) + 1} ${randomFromArray(['Main', 'Oak', 'Maple', 'Cedar', 'Pine'])} St`,
    city: randomFromArray(cities),
    state: randomFromArray(states),
    zip: Math.floor(Math.random() * 90000) + 10000,
    country: randomFromArray(countries),
    preName: Math.random() > 0.5 ? `Dr. ${firstName}` : firstName,
    refCode: `REF${Math.floor(Math.random() * 9999)}`,
    preg: Math.random() > 0.8,
    fastHeart: `${Math.floor(Math.random() * 40) + 60}`,
    glp1: Math.random() > 0.7
  };
};

function App() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    tos: false,
    dob: null,
    gender: '',
    weight: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    preName: '',
    refCode: '',
    preg: false,
    fastHeart: '',
    glp1: false
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchEntries();
  }, []);

  const validateForm = () => {
    const errors = {};
    
    // Basic validation rules
    if (!formData.firstName.trim()) errors.firstName = 'First name is required';
    if (!formData.lastName.trim()) errors.lastName = 'Last name is required';
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.phoneNumber.trim()) errors.phoneNumber = 'Phone number is required';
    if (!formData.dob) errors.dob = 'Date of birth is required';
    if (!formData.gender) errors.gender = 'Gender is required';
    if (!formData.weight) {
      errors.weight = 'Weight is required';
    } else if (isNaN(formData.weight) || formData.weight <= 0) {
      errors.weight = 'Weight must be a positive number';
    }
    if (!formData.address.trim()) errors.address = 'Address is required';
    if (!formData.city.trim()) errors.city = 'City is required';
    if (!formData.state.trim()) errors.state = 'State is required';
    if (!formData.zip) {
      errors.zip = 'ZIP code is required';
    } else if (isNaN(formData.zip) || formData.zip.toString().length < 5) {
      errors.zip = 'ZIP code must be at least 5 digits';
    }
    if (!formData.country.trim()) errors.country = 'Country is required';
    if (!formData.tos) errors.tos = 'You must agree to the Terms of Service';

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchEntries = async () => {
    try {
      const records = await pb.collection('telepath_api').getList(1, 50, {
        sort: '-created',
      });
      setEntries(records.items);
    } catch (error) {
      console.error('Error fetching entries:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching entries: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    // Clear error when field is modified
    if (fieldErrors[name]) {
      setFieldErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleDateChange = (date) => {
    setFormData(prev => ({
      ...prev,
      dob: date
    }));
    if (fieldErrors.dob) {
      setFieldErrors(prev => ({
        ...prev,
        dob: undefined
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validate form before submission
    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: 'Please fix the errors in the form',
        severity: 'error'
      });
      return;
    }

    try {
      const submissionData = {
        ...formData,
        dob: formData.dob ? formData.dob.toISOString() : null,
        weight: parseInt(formData.weight),
        zip: parseInt(formData.zip)
      };
      
      const record = await pb.collection('telepath_api').create(submissionData);
      setSnackbar({
        open: true,
        message: 'Form submitted successfully!',
        severity: 'success'
      });
      // Reset form and errors
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        tos: false,
        dob: null,
        gender: '',
        weight: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        preName: '',
        refCode: '',
        preg: false,
        fastHeart: '',
        glp1: false
      });
      setFieldErrors({});
      // Refresh entries
      fetchEntries();
    } catch (error) {
      // Handle PocketBase validation errors
      if (error.response?.data?.data) {
        const pocketBaseErrors = error.response.data.data;
        const formattedErrors = {};
        Object.keys(pocketBaseErrors).forEach(field => {
          formattedErrors[field] = pocketBaseErrors[field].message;
        });
        setFieldErrors(formattedErrors);
      } else {
        setSnackbar({
          open: true,
          message: 'Error submitting form: ' + error.message,
          severity: 'error'
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleFillRandom = () => {
    setFormData(generateRandomData());
    setFieldErrors({}); // Clear any existing errors
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Patient Registration Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                error={!!fieldErrors.firstName}
                helperText={fieldErrors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                error={!!fieldErrors.lastName}
                helperText={fieldErrors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                error={!!fieldErrors.email}
                helperText={fieldErrors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                error={!!fieldErrors.phoneNumber}
                helperText={fieldErrors.phoneNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  value={formData.dob}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      required: true,
                      error: !!fieldErrors.dob,
                      helperText: fieldErrors.dob
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
                error={!!fieldErrors.gender}
                helperText={fieldErrors.gender}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Weight (lbs)"
                name="weight"
                type="number"
                value={formData.weight}
                onChange={handleChange}
                required
                error={!!fieldErrors.weight}
                helperText={fieldErrors.weight}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
                error={!!fieldErrors.address}
                helperText={fieldErrors.address}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
                error={!!fieldErrors.city}
                helperText={fieldErrors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
                error={!!fieldErrors.state}
                helperText={fieldErrors.state}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="ZIP Code"
                name="zip"
                type="number"
                value={formData.zip}
                onChange={handleChange}
                required
                error={!!fieldErrors.zip}
                helperText={fieldErrors.zip}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
                error={!!fieldErrors.country}
                helperText={fieldErrors.country}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Preferred Name"
                name="preName"
                value={formData.preName}
                onChange={handleChange}
                error={!!fieldErrors.preName}
                helperText={fieldErrors.preName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Reference Code"
                name="refCode"
                value={formData.refCode}
                onChange={handleChange}
                error={!!fieldErrors.refCode}
                helperText={fieldErrors.refCode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Fast Heart Rate"
                name="fastHeart"
                value={formData.fastHeart}
                onChange={handleChange}
                error={!!fieldErrors.fastHeart}
                helperText={fieldErrors.fastHeart}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.tos}
                    onChange={handleChange}
                    name="tos"
                    required
                  />
                }
                label="I agree to the Terms of Service"
              />
              {fieldErrors.tos && (
                <Typography color="error" variant="caption" display="block">
                  {fieldErrors.tos}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.preg}
                    onChange={handleChange}
                    name="preg"
                  />
                }
                label="Are you pregnant?"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.glp1}
                    onChange={handleChange}
                    name="glp1"
                  />
                }
                label="GLP-1"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                size="large"
                onClick={handleFillRandom}
              >
                Fill with Random Data
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Recent Entries
        </Typography>
        {loading ? (
          <Typography>Loading entries...</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.map((entry) => (
                  <TableRow key={entry.id}>
                    <TableCell>
                      {entry.firstName} {entry.lastName}
                    </TableCell>
                    <TableCell>{entry.email}</TableCell>
                    <TableCell>{entry.phoneNumber}</TableCell>
                    <TableCell>
                      {entry.city}, {entry.state}
                    </TableCell>
                    <TableCell>
                      {entry.glp1 ? '✓ GLP-1' : ''}{' '}
                      {entry.preg ? '✓ Pregnant' : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
